import Pagination from '@/components/Components/Pagination/Pagination';
import RoommateAd from "@/components/Components/RoommateAd/RoommateAd";
import Button from "@/components/Common/Buttons/Button/Button";
import xIcon from '@/components/Common/Icons/xIcon';

export default {
    name: 'AdminViewingRequests',
    components: {
        Button,
        RoommateAd,
        Pagination,
        xIcon
    },
    data() {
        return {
            posts: [],
            total: null,
            pages: null,
            isLoading: false,
            postsPerPage: 10,
        };
    },
    methods: {
        async loadPosts() {
            this.isLoading = true;
            try {
                const posts = await this.$store.dispatch('roommate/getUserRoommatePosts', {});
                this.posts = posts.data;
                this.total = posts.total;
                this.pages = Math.ceil(this.total / this.postsPerPage);
            } catch (err) {
                console.log('Error', err)
            } finally {
                this.isLoading = false;
            }
        },
        async deleteRoommatePost(id) {
            await this.$store.dispatch('roommate/deleteRoommatePost', {
                id: id
            });
            this.loadPosts();
        }
    },

    async mounted() {
        if (this.$store.state.user.info.type !== 'user') {
            this.$router.push({name: 'NotFoundPage'})
            return;
        }
        this.loadPosts();
    },
};
