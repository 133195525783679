<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="!isLoading && (typeof posts === 'undefined'|| posts.length === 0)">
    <div class="alert alert-warning text-center mt-3" role="alert">
      {{ $t('user-do-not-have-any-roommate-ads') }}
    </div>
    <div class="mt-4 d-flex justify-content-center">
      <Button
        style="width: 300px"
        @click="$store.state.user.authenticated
                ? $router.push({name: 'AddRoommate'})
                : $store.dispatch('utils/openModal', 'login')"
        :text="$t('publish-new-offer')" :size="'medium'" :theme="'solid-green'"/>
    </div>
  </div>
  <div v-else>
    <div class="add-property-title mb-5"> {{ $t('roommate-offers') }}</div>
    <div class="mb-3" style="position: relative" v-for="post in posts" v-if="!isLoading">
      <div class="close-btn" @click="deleteRoommatePost(post.id)">
        <xIcon/>
      </div>
      <router-link :to="{name: 'EditRoommateOffer', params: {id: post.id}}">
        <RoommateAd :adtype="false == true ? 'premium' : ''"
                    :title="post.title"
                    :name="post.name"
                    :city="post.city.name"
                    :location="post.district.name"
                    :university="post.university"
                    :date="post.date"
                    :price="post.price"
                    :description="post.description"
                    :image="post.images.length > 0 ? post.images[0].src : null"
                    :show-delete-btn="true"
                    @onDelete="deleteRoommatePost(post.id)"
        />
      </router-link>
    </div>
    <div class="pagination">
      <Pagination :size="'small'"/>
    </div>
  </div>
</template>

